$base__heading-size: 32;

.static {
  &__background {
    @include linear-gradient(rgba(242, 242, 242, 1), rgba(255, 255, 255, 1));
  }

  padding: 12px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 140%;
    font-weight: 500;
  }

  h1 {
    //@include font-size($base__heading-size / 1);
    font-size: 3.2rem;
  }

  h2 {
    // @include font-size($base__heading-size / 1.2);
    font-size: 2.66667rem;
  }

  h3 {
    @include font-size($base__heading-size / 1.4);
  }

  h4 {
    //@include font-size($base__heading-size / 1.6);
    font-size: 2rem;
  }

  h5 {
    @include font-size($base__heading-size / 1.8);
  }

  h6 {
    @include font-size($base__heading-size / 2);
  }

  ul {
    margin: 0 0 16px 25px;
  }

  a {
    color: $wowcher-blue;
    text-decoration: underline;
  }

  .faq-question {
    font-weight: 700;
  }

  .slide-panel + div {
    display: block !important;
  }
}
