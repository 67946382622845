.navigation-popover {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  background-color: white;
  animation-fill-mode: backwards;
  animation: popover-in 0.2s;
  padding: 15px 15px 15px 15px;
}
