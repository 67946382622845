@media (min-width: 331px) {
  .app--full-height {
    min-height: calc(100vh - 58px);
  }
}
@media (min-width: 241px) {
  .app--full-height {
    min-height: calc(100vh - 75px);
  }
}
@media (min-width: 189px) {
  .app--full-height {
    min-height: calc(100vh - 90px);
  }
}
@media (min-width: 183px) {
  .app--full-height {
    min-height: calc(100vh - 109px);
  }
}

@media (min-width: $screen-sm) {
  .app {
    &__container {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &--full-height {
      min-height: calc(100vh - 126px);
    }
  }
}