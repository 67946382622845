.tile {
  $tile-padding: 15px;
  background: $color-white;
  border-radius: 0;
  box-shadow: 0 0 4px #bababa;

  &-header {
    position: relative;
    background: $tile-header;
    border-bottom: 1px solid $color-black;
    color: $color-mine-shaft;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: $tile-padding;
  }

  &-body {
    padding: $tile-padding;
    position: relative;
  }

  &-main {
    background: $tile-background;
    padding: $tile-padding;
  }

  @media (min-width: $screen-sm) {
    border-radius: 7px;
    position: relative;

    &-header {
      font-size: 18px;

      &__picture {
        position: absolute;
        top: -15px;
        right: -20px;
        width: 15rem;
        transform: rotate(5deg);
        z-index: 11;
      }
    }

    // Custom style for Claim Gift page
    &--mygift {
      margin: 0 auto;
      max-width: 66%;

      .tile {
        &-main {
          p.right-padding {
            padding-right: 160px;
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    &-header {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
