.input-container {
  position: relative;
  margin-bottom: 15px;

  &.remove-header {
    width: 100%;
  }

  .form-group {
    position: relative;
    margin: 0;
  }

  &__icon {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__hint {
    opacity: 0.3;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-style: italic;
    pointer-events: none;
  }

  &__icon + &__hint {
    right: 45px;
  }

  .form-control {
    font-size: 14px;
    height: auto;
    line-height: 20px;
    padding: 10px 14px;

    &-feedback {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      display: block;
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      pointer-events: none;
    }

    &:focus,
    &--focused {
      box-shadow: 0 1px 12px rgba(51, 51, 51, 0.2);
    }
  }

  &.text-center .form-control {
    text-align: center;
  }

  &.login-page .form-control:focus {
    box-shadow: 0 1px 12px rgba(51, 51, 51, 0.2);
  }

  .bg-danger {
    color: $color-white;
    padding: 6px 12px;
  }
}
