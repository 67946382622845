@font-face {
  font-family: 'FontAwesome';
  src: url('https://resources.wowcher.co.uk/fonts/fontawesome-webfont.woff2?v=4.7.0')
    format('woff');
}

.img-responsive {
  display: block;
  width: 100%;
  height: auto;
  line-height: 0;
}
.infographic__step {
  display: flex;
  padding: 2rem 7.5rem;
  position: relative;
}

html {
  font-size: 62.5%;
}

.popeye-modal-container.popeye-modal-container {
  background-color: rgba(51, 51, 51, 0.8);
  z-index: 4000;
}
.popeye-modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  overflow-y: auto;
  background-color: rgba(76, 147, 234, 0.7);
  text-align: center;
}

.popeye-modal-container:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.popeye-modal-container:before {
  content: '';
  display: inline;
  height: 0;
  vertical-align: inherit;
}

@media (min-width: 768px) {
  .popeye-modal-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.popeye-modal.work-with-us-section {
  padding: 0;
}

.popeye-modal-container .popeye-modal {
  width: 100%;
  height: 100%;
}

.popeye-modal-container .popeye-modal {
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  width: 60%;
  border-radius: 3px;
  border: none;
  z-index: 2;
  padding: 2em 1.5em 1.5em;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.popeye-modal-container .popeye-modal {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .popeye-modal-container .popeye-modal {
    width: 90%;
    max-width: 920px;
    height: auto;
  }
}

.popeye-modal-container
  .popeye-modal.work-with-us-section
  a.popeye-close-modal {
  color: #ffffff;
  font-size: 3rem !important;
  right: 1.5rem;
  top: 1.5rem;
}
.popeye-modal-container .popeye-modal a.popeye-close-modal {
  position: absolute;
  top: 1em;
  right: 1em;
  font-weight: 100;
  text-decoration: none;
  color: inherit;
}
.popeye-modal-container .popeye-modal a.popeye-close-modal:after {
  content: '\D7';
}

span.fa-play-circle {
  font-size: 8rem;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-play-circle:before {
  content: '\f144';
}
