@font-face {
  font-family: 'glyphicons halflings';
  src: url('/fonts/glyphicons/GLYPHICONSHalflings-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.glyphicon {
  top: 1px;
  display: inline-block;
  font-family: glyphicons halflings, sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-envelope:before {
  content: '\2709';
}
.glyphicon-lock:before {
  content: '\e033';
}

@font-face {
  font-family: 'customFont', sans-serif;
  src: url('/fonts/customfont/customFont.eot') format('embedded-opentype'),
    url('/fonts/customfont/customFont.ttf') format('truetype'),
    url('/fonts/customfont/customFont.woff') format('woff'),
    url('/fonts/customfont/customFont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.ico-custom {
  top: 1px;
  display: inline-block;
  font-family: customFont, sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-basket::before {
  content: '\e900';
}

.ico-facebook::before {
  content: '\e905';
}

@font-face {
  font-family: 'Klarna Headline';
  font-display: swap;
  font-weight: 700;
  src: url('https://x.klarnacdn.net/ui/fonts/v1.3/KlarnaHeadline-Bold.woff2')
      format('woff2'),
    url('https://x.klarnacdn.net/ui/fonts/v1.3/KlarnaHeadline-Bold.woff')
      format('woff');
}

// $ico-tick: "\e904";
// $ico-alarm: "\e903";
// $ico-exclamation-circle: "\e902";
// $ico-trash: "\e901";
// $ico-basket: "\e900";
// $ico-mastercard: "\f02d";
// $ico-visa: "\f045";
// $ico-facebook: "\e905";
// $ico-brand: "\e905";
// $ico-social: "\e905";
